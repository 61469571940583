import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [{
		path: "/login",
		name: "logn",
		component: () => import("../views/login.vue"), //用户管理iew
	},
	{
		path: "/",
		component: () => import("../layout/layout_index.vue"),
		redirect: "/EnergOrderList",
		meta: {
			title: "",
			icon: "el-icon-coin",
			requireAuth: true,
		},
		children: [{
				path: "/EnergOrderList",
				component: () =>
					import("../views/EnergOrderList.vue"), //
				meta: {
					title: "能量订单列表",
					icon: "el-icon-eleme",
					requireAuth: true,
				},
			},
			{
				path: "/RechargeOrderList",
				component: () => import("../views/RechargeOrderList.vue"), //
				meta: {
					title: "充值订单列表",
					icon: "el-icon-film",
					requireAuth: true,
				},
			},
			{
				path: "/TRXRedemptionOrderList",
				component: () => import("../views/TRXRedemptionOrderList"), //
				meta: {
					title: "trx兑换订单列表",
					icon: "el-icon-notebook-1",
					requireAuth: true,
				},
			},

			{
				path: "/EnergyAccountBalance",
				component: () => import("../views/EnergyAccountBalance.vue"), //
				meta: {
					title: "能量账户余额",
					icon: "el-icon-bank-card",
					requireAuth: true,
				},
			},
			{
				path: "/SystemSettings",
				component: () => import("../views/SystemSettings.vue"), //用户管理
				meta: {
					title: "系统设置",
					icon: "el-icon-setting",
					requireAuth: true,
				},
			},


			{
				path: "/user_management",
				component: () =>
					import("../views/user_management.vue"), //用户管理
				meta: {
					title: "用户列表",
					icon: "el-icon-user",
					requireAuth: true,
				},
			},

		],
	},
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});
// 路由守卫

// to：即将要进入的目标 路由对象
// from：当前导航正要离开的路由
//next：放行
// router.beforeEach((to, from, next) => {
//   sessionStorage.setItem("isLogin",true);
//   if (to.matched.length != 0) {
//       if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
//           if (sessionStorage.getItem("isLogin")=='true') { // 通过浏览器本地缓存判断当前的token是否存在
//               next();
//           } else {
//               next({
//                   path: '/login',
//                   query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
//               })
//           }
//       } else {
//           if (sessionStorage.getItem("isLogin")=='true') { // 判断是否登录
//               if (to.path != "/" && to.path != "/login") { //判断是否要跳到登录界面
//                   next();
//               } else {
//                   /**
//                    * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页
//                    */
//                   next({
//                       path: '/home'
//                   })
//               }
//           } else {
//               next();
//           }
//       }
//   } else {
//       next({
//           path: '/login',
//           query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
//       })
//   }
// })
router.beforeEach((to, from, next) => {
	// sessionStorage.setItem("isLogin", true);
	if (to.path == "/login") {
		sessionStorage.removeItem("commodity");
	}
	let commodity = JSON.parse(sessionStorage.getItem("isLogin"));
	if (!commodity && to.path != "/login") {
		next({
			path: "/login"
		});
	} else {
		next();
	}
});
export default router;